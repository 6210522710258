import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Loader from '../../../Loader';
import Alert from '../../../Alert';
import { useAlert } from '../../../AlertContainer'; // Importar el contexto de alertas
import '../../StyleSheets/AdminPanel.css';

const PhotogrammetryPackagesList = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { addAlert } = useAlert(); // Usar el contexto de alertas

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://shootingcr.somee.com/api/photogrammetrypackage');
      const data = await response.json();
      setPackages(data);
    } catch (error) {
      console.error('Error fetching packages data:', error);
      addAlert('error', 'Error', 'Failed to fetch packages data.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    navigate('/admin/photogrammetrypackages/create');
  };

  const handleEdit = (id) => {
    navigate(`/admin/photogrammetrypackages/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/photogrammetrypackage/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        setPackages(packages.filter(pkg => pkg.id !== id));
        addAlert('success', 'Success', 'Package deleted successfully!');
      } else {
        console.error('Error deleting package:', await response.text());
        addAlert('error', 'Error', 'Failed to delete package.');
      }
    } catch (error) {
      console.error('Error deleting package:', error);
      addAlert('error', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="photogrammetry-packages">
          {loading && <Loader />}
          <h1>Photogrammetry Packages</h1>
          <button className="btn create-btn" onClick={handleCreate}>Create New Package</button>
          <table className="list-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {packages.map(pkg => (
                <tr key={pkg.id}>
                  <td>{pkg.id}</td>
                  <td>{pkg.name}</td>
                  <td>{pkg.description}</td>
                  <td>{pkg.price}</td>
                  <td>
                    <button className="btn edit-btn" onClick={() => handleEdit(pkg.id)}>Edit</button>
                    <button className="btn delete-btn" onClick={() => handleDelete(pkg.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PhotogrammetryPackagesList;
