import React from 'react';
import '../stylesheets/Policy.css';

const TermsOfUse = () => {
  return (
    <div className="policy-container">
      <h1 className="secondary-heading">Terms of Use</h1>
      <div className='policy-text-container'>
        <p className="text-align-left">
          <strong>Welcome to Shooting_CR's Terms of Use page. Please read these terms carefully before using our services.</strong>
          <br /><br />
          <strong>Acceptance of Terms:</strong>
          <br />
          By accessing and using our services, you accept and agree to be bound by the terms and provisions of this agreement.
          <br /><br />
          <strong>Services Provided:</strong>
          <br />
          Shooting_CR offers a range of services including, but not limited to, photography, videography, and promotional content creation.
          <br /><br />
          <strong>User Obligations:</strong>
          <br />
          As a user, you agree to use our services responsibly and to not engage in any activities that could harm our operations or other users.
          <br /><br />
          <strong>Intellectual Property:</strong>
          <br />
          All content provided through our services, including text, graphics, logos, and images, is the property of Shooting_CR or our content suppliers.
          <br /><br />
          <strong>Limitation of Liability:</strong>
          <br />
          Shooting_CR shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our services.
          <br /><br />
          <strong>Changes to Terms:</strong>
          <br />
          We may modify these terms at any time. We will notify you of any changes by posting the new terms on this page.
          <br /><br />
          <strong>Contact Us:</strong>
          <br />
          If you have any questions about these Terms of Use, please contact us.
        </p>
      </div>
    </div>
  );
}

export default TermsOfUse;
