import React, { useState, useEffect } from 'react';
import AdPackageItem from './microcomponents/AdPackageItem';
import Loader from './Loader';
import NoElements from './NoElements';
import '../stylesheets/List.css';

const AdPackageList = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = async () => {
    try {
      setLoading(true);
      const response = await fetch('/static/media/Ads/data/adpackages.json'); // Ruta del JSON local
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPackages(data);
    } catch (error) {
      console.error('Error fetching packages data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="list-wrapper" id='listpackages'>
      <header className="list-header">
        <h2 className="secondary-subheading">Available Ad Packages</h2>
      </header>
      <section className="list-container" id='PackagesList'>
        {loading && <Loader />}
        {!loading && packages.length === 0 && <NoElements />}
        {!loading && packages.length > 0 && packages.map((pkg) => (
          <AdPackageItem key={pkg.Id} pkg={pkg} />
        ))}
      </section>
    </div>
  );
};

export default AdPackageList;
