import React, { useState, useEffect } from 'react';
import HikingItem from './microcomponents/HikingItem';
import Loader from './Loader';
import NoElements from './NoElements';
import '../stylesheets/List.css'; // Asegúrate de que todos los componentes de lista compartan el mismo archivo CSS

const HikingList = () => {
  const [hikings, setHikings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchHikings();
  }, []);

  const fetchHikings = async () => {
    try {
      setLoading(true);
      const response = await fetch('/static/media/Hikings/data/hikings.json');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setHikings(data);
    } catch (error) {
      console.error('Error fetching hikings data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="list-wrapper" id='listhikings'>
      <header className="list-header">
        <h2 className="secondary-subheading">Available Hikings</h2>
      </header>
      <section className="list-container" id='HikingList'>
        {loading && <Loader />}
        {!loading && hikings.length === 0 && <NoElements />}
        {!loading && hikings.length > 0 && hikings.map((hiking) => (
          <HikingItem key={hiking.HikingId} hiking={hiking} />
        ))}
      </section>
    </div>
  );
};

export default HikingList;
