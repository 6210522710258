import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../stylesheets/List.css';
import noImageIcon from '../../Images/NoImage.webp';

const AdPackageItem = ({ pkg }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/adpackages/${pkg.Id}`);
  };

  return (
    <div className="list-item-container">
      <img 
        src={pkg.AdPackageImages && pkg.AdPackageImages.length > 0 ? pkg.AdPackageImages[0].ImageUrl : noImageIcon} 
        alt={pkg.Name} 
        className="list-item-image" 
      />
      <div className="list-item-details">
        <h3>{pkg.Name}</h3>
        <p>{pkg.Description}</p>
        <p><strong>Price:</strong> ${pkg.Price ? pkg.Price.toLocaleString() : 'N/A'}</p>
        <button className="btn view-details-btn" onClick={handleViewDetails}>View Details</button>
      </div>
    </div>
  );
};

export default AdPackageItem;
