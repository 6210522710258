import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import "aos/dist/aos.css";
import "animate.css/animate.compat.css";
import { AlertProvider } from './components/AlertContainer';

import Navbar from './components/NavBar';
import Hero from './components/Hero';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import HikingList from './components/HikingList';
import HikingDetail from './components/HikingDetail';
import Login from './components/AdminPanel/Auth/Login';
import RegisterAdmin from './components/AdminPanel/Auth/RegisterAdmin';
import AdminPanel from './components/AdminPanel/AdminPanel/AdminPanel';
import RealStateSalesList from './components/AdminPanel/AdminPanel/Sales/RealStateSalesList';
import CreateSale from './components/AdminPanel/AdminPanel/Sales/CreateSale';
import EditSale from './components/AdminPanel/AdminPanel/Sales/EditSale';
import ProtectedRoute from './components/AdminPanel/Auth/ProtectedRoute';
import RealStateList from './components/RealStateList';
import RealStateDetail from './components/RealStateDetail';
import CreateHiking from './components/AdminPanel/AdminPanel/Hikings/CreateHiking';
import EditHiking from './components/AdminPanel/AdminPanel/Hikings/EditHiking';
import HikingListAdmin from './components/AdminPanel/AdminPanel/Hikings/HikingList';
import AdPackageList from './components/AdPackageList';
import AdPackageDetail from './components/AdPackageDetail';
import PhotogrammetryPackageList from './components/PhotogrammetryPackageList';
import PhotogrammetryPackageDetail from './components/PhotogrammetryPackageDetail';
import CreatePhotogrammetryPackage from './components/AdminPanel/AdminPanel/PhotogrammetryPackages/CreatePhotogrammetryPackage';
import EditPhotogrammetryPackage from './components/AdminPanel/AdminPanel/PhotogrammetryPackages/EditPhotogrammetryPackage';
import PhotogrammetryPackagesList from './components/AdminPanel/AdminPanel/PhotogrammetryPackages/PhotogrammetryPackagesList';
import PublicityList from './components/AdminPanel/AdminPanel/Publicity/PublicityList';
import CreatePublicity from './components/AdminPanel/AdminPanel/Publicity/CreatePublicity';
import EditPublicity from './components/AdminPanel/AdminPanel/Publicity/EditPublicity';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';

const LandingPage = () => {
  const links = [
    { title: "Services", href: "services" },
    { title: "About", href: "about" },
    { title: "Portfolio", href: "portfolio" },
    { title: "Contact", href: "contact" }
  ];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Hero 
          title="Welcome to Shooting_CR"
          subtitle="Let us tell you about us"
          buttonText="Show me more"
          targetSection="services"
        />
        <Services />
        <About />
        <Portfolio />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const HikingPage = () => {
  const links = [
    { title: "Hikings", href: "listhikings" },
    { title: "Contact", href: "contact" }
  ];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Hero 
          title="Explore Our Hiking Tours"
          subtitle="Find the best hiking experiences"
          buttonText="See Hikings"
          targetSection="listhikings"
        />
        <HikingList />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const RealStateSalesPage = () => {
  const links = [
    { title: "Estates", href: "listsales" },
    { title: "Contact", href: "contact" }
  ];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Hero 
          title="Discover Your Dream Home"
          subtitle="Explore our real estate listings"
          buttonText="View Estates"
          targetSection="listsales"
        />
        <RealStateList />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const RealStateDetailPage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <RealStateDetail />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const AdPackagePage = () => {
  const links = [
    { title: "Packages", href: "listpackages" },
    { title: "Contact", href: "contact" }
  ];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Hero 
          title="Advertise with Us"
          subtitle="Check out our ad packages"
          buttonText="View Packages"
          targetSection="listpackages"
        />
        <AdPackageList />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const AdPackageDetailPage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <AdPackageDetail />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const PhotogrammetryPackagePage = () => {
  const links = [
    { title: "Packages", href: "listpackages" },
    { title: "Contact", href: "contact" }
  ];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Hero 
          title="Photogrammetry Services"
          subtitle="Explore our photogrammetry packages"
          buttonText="View Packages"
          targetSection="listpackages"
        />
        <PhotogrammetryPackageList />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const PhotogrammetryPackageDetailPage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <PhotogrammetryPackageDetail />
        <Contact />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const LoginPage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <Login />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const TermsOfUsePage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <TermsOfUse />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

const PrivacyPolicyPage = () => {
  const links = [];

  return (
    <main>
      <header className='header'>
        <Navbar links={links} />
      </header>
      <section className='content'>
        <PrivacyPolicy />
        <footer>
          <Footer />
        </footer>
      </section>
    </main>
  );
};

function App() {
  return (
    <div className='App'>
      <Router>
        <AlertProvider>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/hiking" element={<HikingPage />} />
            <Route path="/hiking/:id" element={<HikingDetail />} />
            <Route path="/realstatesales" element={<RealStateSalesPage />} />
            <Route path="/realstatesales/:id" element={<RealStateDetailPage />} />
            <Route path="/adpackages" element={<AdPackagePage />} />
            <Route path="/adpackages/:id" element={<AdPackageDetailPage />} />
            <Route path="/photogrammetrypackages" element={<PhotogrammetryPackagePage />} />
            <Route path="/photogrammetrypackages/:id" element={<PhotogrammetryPackageDetailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admin/registeradmin" element={
              <ProtectedRoute>
                <RegisterAdmin />
              </ProtectedRoute>
            } />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } />
            <Route path="/admin/realstatesales" element={
              <ProtectedRoute>
                <RealStateSalesList />
              </ProtectedRoute>
            } />
            <Route path="/admin/realstatesales/create" element={
              <ProtectedRoute>
                <CreateSale />
              </ProtectedRoute>
            } />
            <Route path="/admin/realstatesales/edit/:id" element={
              <ProtectedRoute>
                <EditSale />
              </ProtectedRoute>
            } />
            <Route path="/admin/hikings" element={
              <ProtectedRoute>
                <HikingListAdmin />
              </ProtectedRoute>
            } />
            <Route path="/admin/hikings/create" element={
              <ProtectedRoute>
                <CreateHiking />
              </ProtectedRoute>
            } />
            <Route path="/admin/hikings/edit/:id" element={
              <ProtectedRoute>
                <EditHiking />
              </ProtectedRoute>
            } />
            <Route path="/admin/photogrammetrypackages" element={
              <ProtectedRoute>
                <PhotogrammetryPackagesList />
              </ProtectedRoute>
            } />
            <Route path="/admin/photogrammetrypackages/create" element={
              <ProtectedRoute>
                <CreatePhotogrammetryPackage />
              </ProtectedRoute>
            } />
            <Route path="/admin/photogrammetrypackages/edit/:id" element={
              <ProtectedRoute>
                <EditPhotogrammetryPackage />
              </ProtectedRoute>
            } />
            <Route path="/admin/adpackage" element={
              <ProtectedRoute>
                <PublicityList />
              </ProtectedRoute>
            } />
            <Route path="/admin/adpackage/create" element={
              <ProtectedRoute>
                <CreatePublicity />
              </ProtectedRoute>
            } />
            <Route path="/admin/adpackage/edit/:id" element={
              <ProtectedRoute>
                <EditPublicity />
              </ProtectedRoute>
            } />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-use" element={<TermsOfUsePage />} />
          </Routes>
        </AlertProvider>
      </Router>
    </div>
  );
}

export default App;
