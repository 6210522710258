import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../stylesheets/List.css';
import noImageIcon from '../../Images/NoImage.webp';

const RealStateItem = ({ sale }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/realstatesales/${sale.Id}`);
  };

  return (
    <div className="list-item-container">
      <img 
        src={sale.RealEstateImages && sale.RealEstateImages.length > 0 ? sale.RealEstateImages[0].ImageUrl : noImageIcon} 
        alt={sale.PropertyName} 
        className="list-item-image" 
      />
      <div className="list-item-details">
        <h3>{sale.PropertyName}</h3>
        <p>{sale.Description}</p>
        <p><strong>Price:</strong> ${sale.Price ? sale.Price.toLocaleString() : 'N/A'}</p>
        <button className="btn view-details-btn" onClick={handleViewDetails}>View Details</button>
      </div>
    </div>
  );
};

export default RealStateItem;
