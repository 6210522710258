import React from "react";
import "../stylesheets/Footer.css";
import { FaInstagram } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
        <div> 
          <p  className="primary-text">Shooting_CR 2023</p>
        </div>
        <div className="social-container">
          <a  href="https://www.instagram.com/shooting_cr?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><FaInstagram /></a>
          <a href="https://www.youtube.com/@Shooting_CR"><BsYoutube /></a>
          <a href="https://www.facebook.com/profile.php?id=61550794846346&mibextid=LQQJ4d"><FaFacebookF /></a>
          <a href="https://www.tiktok.com/@shooting_cr?_t=8izHi357gbl&_r=1"><FaTiktok/></a>
        </div>
        <div>
          <a className="primary-text" href="/privacy-policy">Privacy Policy</a>
          <a className="primary-text" href="/terms-of-use">Terms of Use</a>
        </div>
    </div>
  );
};

export default Footer;
