import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Loader from './Loader';
import '../stylesheets/Detail.css';
import noImageIcon from '../Images/NoImage.webp';

const PhotogrammetryPackageDetail = () => {
  const { id } = useParams();
  const [pkg, setPackage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPackage();
  }, [id]);

  const fetchPackage = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/PhotogrammetryPackage/${id}`);
      const data = await response.json();
      setPackage(data);
    } catch (error) {
      console.error('Error fetching package data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!pkg) {
    return <div>Error loading package details</div>;
  }

  return (
    <section className="detail-container">
      <article className="item-detail-container">
        <div className="detail-images">
          {pkg.photogrammetryPackageImages && pkg.photogrammetryPackageImages.length > 0 ? (
            <Carousel autoPlay infiniteLoop showThumbs={false}>
              {pkg.photogrammetryPackageImages.map((image, index) => (
                <div key={index} className="slider-image-container">
                  <img src={image.imageUrl} alt={`${pkg.name} ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <img src={noImageIcon} alt="No images available" />
          )}
        </div>
        <div className="detail-info">
          <h1 className='secondary-subheading'>{pkg.name}</h1>
          <p className='secondary-text'>{pkg.description}</p>
          <p className='secondary-text'><strong>Price:</strong> ${pkg.price}</p>
        </div>
      </article>
    </section>
  );
};

export default PhotogrammetryPackageDetail;
