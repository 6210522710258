import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Loader from './Loader';
import '../stylesheets/Detail.css';
import noImageIcon from '../Images/NoImage.webp';

const HikingDetail = () => {
  const { id } = useParams();
  const [hiking, setHiking] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchHiking();
  }, [id]);

  const fetchHiking = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/hiking/${id}`);
      const data = await response.json();
      setHiking(data);
    } catch (error) {
      console.error('Error fetching hiking data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!hiking) {
    return <div>Error loading hiking details</div>;
  }

  return (
    <section className="detail-container">
      <article className="item-detail-container">
        <div className="detail-images">
          {hiking.hikingImages && hiking.hikingImages.length > 0 ? (
            <Carousel autoPlay infiniteLoop showThumbs={false}>
              {hiking.hikingImages.map((image, index) => (
                <div key={index} className="slider-image-container">
                  <img src={image.imageUrl} alt={`${hiking.title} ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <img src={noImageIcon} alt="No images available" />
          )}
        </div>
        <div className="detail-info">
          <h1 className='secondary-subheading'>{hiking.title}</h1>
          <p className='secondary-text'>{hiking.description}</p>
          <p className='secondary-text'><strong>Price:</strong> ${hiking.price}</p>
          <p className='secondary-text'><strong>Date:</strong> {hiking.date}</p>
          <p className='secondary-text'><strong>People Capacity:</strong> {hiking.peopleCapacity}</p>
          <p className='secondary-text'><strong>Details:</strong> {hiking.details}</p>
        </div>
      </article>
    </section>
  );
};

export default HikingDetail;
