import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../StyleSheets/LoginAndRegister.css';
import Loader from '../../Loader';
import { useAlert } from '../../AlertContainer'; // Importar el contexto de alertas

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { addAlert } = useAlert(); // Usar el contexto de alertas

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://shootingcr.somee.com/api/User/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        addAlert('success', 'Success', 'Login successful!');
        setTimeout(() => {
          window.location.href = '/admin';
        }, 500);
      } else {
        addAlert('error', 'Error', 'Login failed');
      }
    } catch (error) {
      addAlert('error', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <section className='LoginAndRegister-Container'>
        {loading && <Loader />}
        <div className="form-container-loginandregister login">
          <div className="h1">Login</div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input value="Login" className="btn" type="submit" />
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
