import React, { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import Btn from "./microcomponents/Btn";
import "../stylesheets/Navbar.css";
import logo from '../Images/LOGO.svg';

const Navbar = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  return (
    <nav className="nav-container">
      <div className="nav__logo">
        <a href="/"><img src={logo} alt="Logo" /></a>
      </div>

      <div
        className={`nav__menu ${showMenu ? "show-menu" : ""}`}
        id="nav-menu"
      >
        <ul className="nav__list">
          {links.map((link, index) => (
            <li className="nav__item" key={index}>
              <Btn titulo={link.title} href={link.href} onClick={closeMenuOnMobile} />
            </li>
          ))}
        </ul>
        <div className="nav__close" id="nav-close" onClick={toggleMenu}>
          <IoClose />
        </div>
      </div>

      <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
        <IoMenu />
      </div>
    </nav>
  );
};

export default Navbar;
