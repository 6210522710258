import React from 'react';
import '../stylesheets/Hero.css';
import Btn from './microcomponents/Btn';

const Hero = ({ title, subtitle, buttonText, targetSection }) => {
  const scrollToSection = () => {
    const section = document.getElementById(targetSection);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with ID ${targetSection} not found.`);
    }
  };

  return (
    <section className="hero-container" id="hero">
      <div>
        <h1 className="primary-heading">
          {title}
        </h1>
        <p className="primary-subheading">{subtitle}</p>
      </div>
      <div>
        <Btn titulo={buttonText} onClick={scrollToSection} />
      </div>
    </section>
  );
};

export default Hero;
