import React, { useState, createContext, useContext } from 'react';
import Alert from './Alert';
import '../stylesheets/Alert.css';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (type, title, message, autoCloseTime = 5000) => {
    const id = Math.random().toString(36).substr(2, 9);
    setAlerts([...alerts, { id, type, title, message, autoCloseTime }]);
  };

  const removeAlert = (id) => {
    setAlerts(alerts.filter(alert => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ addAlert }}>
      {children}
      <div className="alert-container">
        {alerts.map(alert => (
          <Alert
            key={alert.id}
            type={alert.type}
            title={alert.title}
            message={alert.message}
            onClose={() => removeAlert(alert.id)}
            autoCloseTime={alert.autoCloseTime}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
};
