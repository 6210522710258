import React from 'react';
import { ItemPortfolio } from './microcomponents/ItemPortfolio';
import '../stylesheets/Portfolio.css';
import fotoCocoBolo1 from '../Images/Portfolio/COCOBOLO/1.jpeg';
import fotoCocoBolo2 from '../Images/Portfolio/COCOBOLO/2.jpeg';
import fotoATVWheels1 from '../Images/Portfolio/ARENAL ATV/1.jpeg';
import fotoATVWheels2 from '../Images/Portfolio/ARENAL ATV/2.jpeg';
import fotoATVWheels3 from '../Images/Portfolio/ARENAL ATV/3.jpeg';
import fotoATVWheels4 from '../Images/Portfolio/ARENAL ATV/4.jpeg';

const Portfolio = () => {
  return (
    <section className="portfolio-container" id="portfolio">
      <div className="portfolio-desc-container">
        <h2 className="secondary-heading">Portfolio</h2>
        <p className="secondary-text">
          A collection of my work showcasing my skills and expertise.
        </p>
      </div>
      <div className="portfolio-items-container">
        <ItemPortfolio
          itemPortfolio={{
            title: "Casa Cocobolo",
            description: `Our video for Casa Cocobolo in Montezuma, Costa Rica, offers a captivating
              view of this exclusive retreat. With stunning aerial shots and immersive imagery, we highlight 
              the property's ocean views and infinity pool.`,
            images: [fotoCocoBolo1, fotoCocoBolo2],
            service: "publicity"
          }}
        />
        <ItemPortfolio
          itemPortfolio={{
            title: "Arenal ATV",
            description: `Our video for Arenal ATV Wheels captures the thrilling adventure of exploring 
              Fortuna, Costa Rica, on ATVs. Carefully planned shots showcase the excitement of volcanic landscapes,
              expertly edited to highlight speed and action. It's an invitation to experience the adrenaline 
              rush and natural beauty of Costa Rica's landscapes.`,
            images: [fotoATVWheels1, fotoATVWheels2, fotoATVWheels3, fotoATVWheels4],
            service: "publicity"
          }}
        />
      </div>
    </section>
  );
}

export default Portfolio;
