import React from 'react';
import { Link } from 'react-router-dom';

const Service = (props) => {
  return (
    <div className='service-item-container'>
      <div className='img-service-container'>
        <Link to={props.props.link}>{props.props.icon}</Link>
      </div>
      <div className='text-container'>
        <h1 className='secondary-subheading'>{props.props.title}</h1>
        <p className='secondary-text'>{props.props.description}</p>
      </div>
    </div>
  );
};

export default Service;
