import React from 'react';
import { useNavigate } from 'react-router-dom';
import noImageIcon from '../../Images/NoImage.webp';
import '../../stylesheets/List.css';

const HikingItem = ({ hiking }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/hiking/${hiking.HikingId}`);
  };

  return (
    <div className="list-item-container">
      <img 
        src={hiking.HikingImages && hiking.HikingImages.length > 0 ? hiking.HikingImages[0].ImageUrl : noImageIcon} 
        alt={hiking.Description} 
        className="list-item-image" 
      />
      <div className="list-item-details">
        <h3>{hiking.Description}</h3>
        <p><strong>Date:</strong> {new Date(hiking.Date).toLocaleDateString()}</p>
        <p><strong>People Capacity:</strong> {hiking.PeopleCapacity}</p>
        <p><strong>Price:</strong> ${hiking.Price.toLocaleString()}</p>
        <button className="btn view-details-btn" onClick={handleViewDetails}>View Details</button>
      </div>
    </div>
  );
};

export default HikingItem;
