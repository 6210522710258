import React from 'react';
import '../stylesheets/Contact.css';
import Btn from './microcomponents/Btn';
import { FaInstagram } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

export const Contact = () => {
    return (
        <section className='contact-container' id='contact'>
            <div className='contact-text-container'>
                <h1 className='primary-heading'>Contact Us</h1>
            </div>
            <div className='contact-items-container'>
                <div className='contact-item-container'>
                    <h2 className='primary-subheading'>Contact us by e-mail</h2>
                    <p className='primary-text'>
                        If you prefer to contact us by e-mail, please fill in the form below.
                    </p>
                    <Btn titulo="Contact" href="https://wa.me/<+50684686109>?text=<mensaje_personalizado" />
                </div>
                
                <div className='contact-item-container'>
                    <h2 className='primary-subheading'>Contact us by WhatsApp</h2>
                    <p className='primary-text'>
                        You can contact us via WhatsApp by clicking on the following button.
                    </p>
                    <Btn titulo="Contact" href="https://wa.me/<+50684686109>?text=<mensaje_personalizado" />
                </div>

                <div className='contact-item-container'>
                    <h1 className='primary-heading'>Follow us</h1>
                    <div className="social-contact-container">
                        <a href="https://www.instagram.com/shooting_cr?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="social-link"><FaInstagram /></a>
                        <a href="https://www.youtube.com/@Shooting_CR" className="social-link"><BsYoutube /></a>
                        <a href="https://www.facebook.com/profile.php?id=61550794846346&mibextid=LQQJ4d" className="social-link"><FaFacebookF /></a>
                        <a href="https://www.tiktok.com/@shooting_cr?_t=8izHi357gbl&_r=1" className="social-link"><FaTiktok/></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
