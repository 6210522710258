import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Loader from '../../../Loader';
import Alert from '../../../Alert';
import { useAlert } from '../../../AlertContainer'; // Importar el contexto de alertas
import '../../StyleSheets/AdminPanel.css';

const HikingList = () => {
  const [hikings, setHikings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlert(); // Usar el contexto de alertas
  const navigate = useNavigate();

  useEffect(() => {
    fetchHikings();
  }, []);

  const fetchHikings = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://shootingcr.somee.com/api/hiking');
      const data = await response.json();
      setHikings(data);
    } catch (error) {
      console.error('Error fetching hikings data:', error);
      addAlert('error', 'Error', 'Failed to fetch hikings data.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    navigate('/admin/hikings/create');
  };

  const handleEdit = (id) => {
    navigate(`/admin/hikings/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/hiking/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        setHikings(hikings.filter(hiking => hiking.hikingId !== id));
        addAlert('success', 'Success', 'Hiking deleted successfully!');
      } else {
        console.error('Error deleting hiking:', await response.text());
        addAlert('error', 'Error', 'Failed to delete hiking.');
      }
    } catch (error) {
      console.error('Error deleting hiking:', error);
      addAlert('error', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="list-table">
          {loading && <Loader />}
          <h1>Hikings</h1>
          <button className="btn create-btn" onClick={handleCreate}>Create New Hiking</button>
          <table className="list-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {hikings.map(hiking => (
                <tr key={hiking.hikingId}>
                  <td>{hiking.hikingId}</td>
                  <td>{hiking.title}</td>
                  <td>{hiking.description}</td>
                  <td>{hiking.price}</td>
                  <td>
                    <button className="btn edit-btn" onClick={() => handleEdit(hiking.hikingId)}>Edit</button>
                    <button className="btn delete-btn" onClick={() => handleDelete(hiking.hikingId)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HikingList;
