import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import '../../stylesheets/Portfolio.css'; // Asegúrate de ajustar la ruta según tu estructura de carpetas

export const ItemPortfolio = ({ itemPortfolio }) => {
  return (
    <div className='portfolio-item-container'>
      <div className='img-portfolio-container'>
        <Carousel autoPlay infiniteLoop showThumbs={false}>
          {itemPortfolio.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${itemPortfolio.title} ${index + 1}`} />
            </div>
          ))}
        </Carousel>
        <p className="text-desc-portfolio">{itemPortfolio.description}</p>
      </div>
      <div className='title-portfolio-container'>
        <h1>{itemPortfolio.title}</h1>
      </div>
    </div>
  );
};
