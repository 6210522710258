import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Card from './Card';
import '../StyleSheets/AdminPanel.css';
import { FaBullhorn, FaHiking, FaRuler, FaHandshake, FaList } from 'react-icons/fa';

const AdminPanel = () => {
  const navigate = useNavigate();

  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await fetch('https://shootingcr.somee.com/api/User/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        console.error('Token verification error:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      }
    }
  };

  const handleCreateHiking = async () => {
    await verifyToken();
    navigate('/admin/hikings/create');
  };

  const handleCreateRealState = async () => {
    await verifyToken();
    navigate('/admin/realstatesales/create');
  };

  const handleCreatePhotogrammetry = async () => {
    await verifyToken();
    navigate('/admin/photogrammetrypackages/create');
  };

  const handleCreatePublicity = async () => {
    await verifyToken();
    navigate('/admin/adpackage/create');
  };

  const handleListHiking = async () => {
    await verifyToken();
    navigate('/admin/hikings');
  };

  const handleListRealState = async () => {
    await verifyToken();
    navigate('/admin/realstatesales');
  };

  const handleListPublicity = async () => {
    await verifyToken();
    navigate('/admin/adpackage');
  };

  const handleListPhotogrammetry = async () => {
    await verifyToken();
    navigate('/admin/photogrammetrypackages');
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="card-container">
          <Card
            title="Create Hiking"
            description="Create a new hiking trip"
            actionText="Create Hiking"
            onAction={handleCreateHiking}
            icon={<FaHiking size={50} />}
          />
          <Card
            title="Create Real State Sale"
            description="Create a new real state sale"
            actionText="Create Real State Sale"
            onAction={handleCreateRealState}
            icon={<FaHandshake size={50} />}
          />
          <Card
            title="Create Publicity"
            description="Create a new publicity package"
            actionText="Create Publicity"
            onAction={handleCreatePublicity}
            icon={<FaBullhorn size={50} />}
          />
          <Card
            title="Create Photogrammetry"
            description="Create a new photogrammetry package"
            actionText="Create Photogrammetry"
            onAction={handleCreatePhotogrammetry}
            icon={<FaRuler size={50} />}
          />
          <Card
            title="List Hiking"
            description="View all hiking trips"
            actionText="List Hiking"
            onAction={handleListHiking}
            icon={<FaList size={50} />}
          />
          <Card
            title="List Real State Sale"
            description="View all real state sales"
            actionText="List Real State Sale"
            onAction={handleListRealState}
            icon={<FaList size={50} />}
          />
          <Card
            title="List Publicity"
            description="View all publicity packages"
            actionText="List Publicity"
            onAction={handleListPublicity}
            icon={<FaList size={50} />}
          />
          <Card
            title="List Photogrammetry"
            description="View all photogrammetry packages"
            actionText="List Photogrammetry"
            onAction={handleListPhotogrammetry}
            icon={<FaList size={50} />}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
