import React from 'react';
import '../stylesheets/About.css';
import AboutV from "../Images/videoabout.webm";
const About = () => {

  return (
    <section className="about-section-container" id="about">
      <div className="about-section-video-container">
        <video autoPlay loop muted playsInline>
          <source src={AboutV} type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="about-section-video-text" >
          <h2 className="secondary-subheading">About Us</h2>
          <p className="secondary-text">
            A Costa Rican company dedicated to the use of drones for the satisfaction of multiple clients in order to solve some needs through unmanned aircraft such as land measurements, taking and editing videos, advertising, selling real estate, among others. Apart from this in Shooting CR we are also dedicated to captivating our clients with different tourist destinations around Costa Rica presenting its natural beauty and abundant diversity of flora and fauna.
          </p>
        </div>
      </div>
      <div 
        className="about-section-text-container"
      >
        <div className="secondary-subheading">Our Mission</div>
        <p className="secondary-text">
          Meet all the requests and challenges assigned by our clients through the sophisticated technology of our work teams capturing a whole world of emotions behind our lenses and giving satisfactory results to the requests of our customers.
        </p>
        <div className="secondary-subheading">Our Vision</div>
        <p className="secondary-text">
          Our vision at Shooting CR is to become an exceptional benchmark in all the branches offered by this company, standing out for our wonderful creativity and commitment to excellence in customer service. We seek to consolidate our position as a reliable partner for all our clients, offering the best experiences and dedicated work to all the people who take us as their preference, thus driving our growth and recognition in the market and in the same way standing out as the best in each of our fields of work.
        </p>
      </div>
    </section>
  );
};

export default About;