import React from 'react';
import { useNavigate } from 'react-router-dom';
import noImageIcon from '../../Images/NoImage.webp';
import '../../stylesheets/List.css';

const PackageItem = ({ pkg }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/photogrammetrypackages/${pkg.Id}`);
  };

  return (
    <div className="list-item-container">
      <img 
        src={pkg.PhotogrammetryPackageImages && pkg.PhotogrammetryPackageImages.length > 0 ? pkg.PhotogrammetryPackageImages[0].ImageUrl : noImageIcon} 
        alt={pkg.Name} 
        className="list-item-image" 
      />
      <div className="list-item-details">
        <h3>{pkg.Name}</h3>
        <p>{pkg.Description}</p>
        <p><strong>Price:</strong> ${pkg.Price.toLocaleString()}</p>
        <button className="btn view-details-btn" onClick={handleViewDetails}>View Details</button>
      </div>
    </div>
  );
};

export default PackageItem;
