import React, { useState, useEffect } from 'react';
import RealStateItem from './microcomponents/RealStateItem';
import Loader from './Loader';
import NoElements from './NoElements';
import '../stylesheets/List.css';

const RealStateList = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      setLoading(true);
      const response = await fetch('/static/media/Sales/data/realestatesales.json'); 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSales(data);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="list-wrapper" id='listsales'>
      <header className="list-header">
        <h2 className="secondary-subheading">Available Real Estate Sales</h2>
      </header>
      <section className="list-container" id='SalesList'>
        {loading && <Loader />}
        {!loading && sales.length === 0 && <NoElements />}
        {!loading && sales.length > 0 && sales.map((sale) => (
          <RealStateItem key={sale.Id} sale={sale} />
        ))}
      </section>
    </div>
  );
};

export default RealStateList;
