import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../StyleSheets/Sidebar.css';
import logo from '../../../Images/LOGO.svg';
import { FaHome, FaHiking, FaBuilding, FaBullhorn, FaRuler, FaUserPlus, FaTachometerAlt } from 'react-icons/fa';

const Sidebar = () => {
  const navigate = useNavigate();

  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      try {
        const response = await fetch('https://shootingcr.somee.com/api/User/verify-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Token verification failed');
        }
      } catch (error) {
        console.error('Token verification error:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      }
    }
  };

  const handleNavigation = async (path) => {
    await verifyToken();
    navigate(path);
  };

  return (
    <div className="sidebar">
      <div>
        <div className="sidebar-header">
          <a href="/admin"><img src={logo} alt="Logo" /></a>
        </div>
        <ul className="sidebar-menu">
          <li>
            <button onClick={() => handleNavigation('/admin')}>
              <FaTachometerAlt className="sidebar-icon" /> Dashboard
            </button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/admin/hikings')}>
              <FaHiking className="sidebar-icon" /> Hikings
            </button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/admin/realstatesales')}>
              <FaBuilding className="sidebar-icon" /> Real Estate Sales
            </button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/admin/adpackage')}>
              <FaBullhorn className="sidebar-icon" /> Publicity
            </button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/admin/photogrammetrypackages')}>
              <FaRuler className="sidebar-icon" /> Photogrammetry Packages
            </button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/admin/registeradmin')}>
              <FaUserPlus className="sidebar-icon" /> Register Admin
            </button>
          </li>
        </ul>
      </div>
      <div>
        <ul className="sidebar-menu">
          <li>
            <button onClick={() => handleNavigation('/')}>
              <FaHome className="sidebar-icon" /> Home
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
