import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Loader from '../../../Loader';
import Alert from '../../../Alert';
import { useAlert } from '../../../AlertContainer'; // Importar el contexto de alertas
import '../../StyleSheets/AdminPanel.css';

const EditPublicity = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState(null); // Estado para el mensaje de alerta
  const navigate = useNavigate();
  const { addAlert } = useAlert(); // Usar el contexto de alertas

  useEffect(() => {
    const fetchPublicity = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://shootingcr.somee.com/api/adpackage/${id}`);
        const data = await response.json();
        setName(data.name);
        setDescription(data.description);
        setPrice(data.price);
      } catch (error) {
        console.error('Error fetching publicity data:', error);
        setAlert({ type: 'error', title: 'Error', message: 'Failed to fetch publicity data.' });
      } finally {
        setLoading(false);
      }
    };

    fetchPublicity();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('Id', id);
    formData.append('Name', name);
    formData.append('Description', description);
    formData.append('Price', price);
    images.forEach(image => formData.append('images', image));

    try {
      const response = await fetch(`https://shootingcr.somee.com/api/adpackage/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      });

      if (response.ok) {
        addAlert('success', 'Success', 'Publicity updated successfully!');
        setTimeout(() => navigate('/admin/adpackage'), 3000); // Navega después de 3 segundos
      } else {
        console.error('Error updating publicity:', await response.text());
        addAlert('error', 'Error', 'Failed to update publicity.');
      }
    } catch (error) {
      console.error('Error updating publicity:', error);
      addAlert('error', 'Error', error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="form-container">
          {(loading || isSubmitting) && <Loader />}
          {alert && (
            <Alert
              type={alert.type}
              name={alert.name}
              message={alert.message}
              onClose={() => setAlert(null)}
            />
          )}
          <h1>Edit Publicity</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
            <input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
            <div
              className="file-upload-container"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className="file-upload-header">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p>Browse File to upload or drag and drop files here!</p>
              </div>
              <label htmlFor="file-input" className="file-upload-footer">
                <svg fill="#000000" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.331 6H8.5v20h15V14.154h-8.169z"></path>
                  <path d="M18.153 6h-.009v5.342H23.5v-.002z"></path>
                </svg>
                <p>{images.length > 0 ? images.map(file => file.name).join(', ') : 'No items selected'}</p>
              </label>
              <input id="file-input" type="file" multiple onChange={handleFileChange} />
            </div>
            <button type="submit" className="btn submit-btn" disabled={isSubmitting}>Update</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPublicity;
