import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../StyleSheets/Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [error, setError] = useState(null);

  const handleLogout = async () => {
    try {
      const response = await fetch('https://shootingcr.somee.com/api/User/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Logout failed: ${errorMessage}`);
      }

      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      setError(error.message);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <nav className="navbar-admin">
      <div className="navbar-admin-title">
        <h1 className="secondary-subheading">Admin Panel</h1>
        <span className="secondary-text">Bienvenido {user?.fullName}</span>
      </div>
      <div className="navbar-admin-content">
        <div className="navbar-admin-user-info" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <p>{user?.email}</p>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
    </nav>
  );
};

export default Navbar;
