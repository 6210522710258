import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Loader from '../../../Loader';
import '../../StyleSheets/AdminPanel.css';

const PublicityList = () => {
  const [publicities, setPublicities] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPublicities();
  }, []);

  const fetchPublicities = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://shootingcr.somee.com/api/adpackage');
      const data = await response.json();
      setPublicities(data);
    } catch (error) {
      console.error('Error fetching publicities data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = () => {
    navigate('/admin/adpackage/create');
  };

  const handleEdit = (id) => {
    navigate(`/admin/adpackage/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/adpackage/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        setPublicities(publicities.filter(publicity => publicity.id !== id));
      } else {
        console.error('Error deleting publicity:', await response.text());
      }
    } catch (error) {
      console.error('Error deleting publicity:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="publicity-list">
          {loading && <Loader />}
          <h1>Publicities</h1>
          <button className="btn create-btn" onClick={handleCreate}>Create New Publicity</button>
          <table className="list-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {publicities.map(publicity => (
                <tr key={publicity.id}>
                  <td>{publicity.id}</td>
                  <td>{publicity.name}</td>
                  <td>{publicity.description}</td>
                  <td>{publicity.price}</td>
                  <td>
                    <button className="btn edit-btn" onClick={() => handleEdit(publicity.id)}>Edit</button>
                    <button className="btn delete-btn" onClick={() => handleDelete(publicity.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PublicityList;
