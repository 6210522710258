import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../AdminPanel/Sidebar';
import Navbar from '../AdminPanel/Navbar';
import Loader from '../../Loader';
import { useAlert } from '../../AlertContainer'; 
import '../StyleSheets/AdminPanel.css';

const RegisterAdmin = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { addAlert } = useAlert(); // Usar el contexto de alertas
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestBody = JSON.stringify({
      fullName, 
      phoneNumber, 
      dateOfBirth, 
      email, 
      password, 
      role: 'Admin'
    });

    console.log('Request Body:', requestBody); // Verifica el cuerpo de la solicitud

    try {
      const response = await fetch('https://shootingcr.somee.com/api/User/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Añade el encabezado de autorización
        },
        body: requestBody,
      });

      const data = await response.json();

      if (!response.ok) {
        console.log('Response Data:', data); // Verifica los datos de la respuesta
        throw new Error(data.message || 'Registration failed');
      }

      addAlert('success', 'Success', 'Admin registered successfully');
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    } catch (error) {
      addAlert('error', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="form-container">
          {loading && <Loader />}
          <h1>Register Admin</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <input
              type="date"
              placeholder="Date of Birth"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="btn submit-btn" disabled={loading}>Register</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterAdmin;
