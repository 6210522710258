import React from 'react';
import '../stylesheets/Policy.css';

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1 className="secondary-heading">Privacy Policy</h1>
      <div className='policy-text-container'>
        <p className="text-align-left secondary-text">
          <strong>Welcome to Shooting_CR's Privacy Policy page. Your privacy is of utmost importance to us.</strong>
          <br /><br />
          <strong>Information Collection and Use:</strong>
          <br />
          We collect various types of information in connection with the services we provide, including:
          <ul>
            <li>Personal Identification Information (Name, email address, phone number, etc.)</li>
            <li>Usage Data (e.g., pages visited, time spent on the site, etc.)</li>
          </ul>
          <strong>How We Use Your Information:</strong>
          <br />
          The information we collect is used in the following ways:
          <ul>
            <li>To provide and maintain our services</li>
            <li>To notify you about changes to our services</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information to improve our services</li>
            <li>To monitor the usage of our service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
          <strong>Data Security:</strong>
          <br />
          We use administrative, technical, and physical security measures to help protect your personal information.
          <br /><br />
          <strong>Changes to This Privacy Policy:</strong>
          <br />
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          <br /><br />
          <strong>Contact Us:</strong>
          <br />
          If you have any questions about this Privacy Policy, please contact us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
