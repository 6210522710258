import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Loader from './Loader';
import '../stylesheets/Detail.css';
import noImageIcon from '../Images/NoImage.webp';

const RealStateDetail = () => {
  const { id } = useParams();
  const [sale, setSale] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSale();
  }, [id]);

  const fetchSale = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://shootingcr.somee.com/api/RealEstateSale/${id}`);
      const data = await response.json();
      setSale(data);
    } catch (error) {
      console.error('Error fetching sale data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!sale) {
    return <div>Error loading sale details</div>;
  }

  return (
    <section className="detail-container">
      <article className="item-detail-container">
        <div className="detail-images">
          {sale.realEstateImages && sale.realEstateImages.length > 0 ? (
            <Carousel autoPlay infiniteLoop showThumbs={false}>
              {sale.realEstateImages.map((image, index) => (
                <div key={index} className="slider-image-container">
                  <img src={image.imageUrl} alt={`${sale.propertyName} ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <img src={noImageIcon} alt="No images available" />
          )}
        </div>
        <div className="detail-info">
          <h1 className='secondary-subheading'>{sale.propertyName}</h1>
          <p className='secondary-text'>{sale.description}</p>
          <p className='secondary-text'><strong>Price:</strong> ${sale.price}</p>
          <p className='secondary-text'><strong>Location:</strong> {sale.location}</p>
          <p className='secondary-text'><strong>Square Feet:</strong> {sale.squareFeet}</p>
          <p className='secondary-text'><strong>Map Link:</strong> <a href={sale.mapLink} target="_blank" rel="noopener noreferrer">View Map</a></p>
        </div>
      </article>
    </section>
  );
};

export default RealStateDetail;
