import React, { useEffect } from 'react';
import '../stylesheets/Alert.css';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaExclamationCircle } from 'react-icons/fa';

const Alert = ({ type, title, message, onClose }) => {
  let icon;
  let alertClass;

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  switch (type) {
    case 'success':
      icon = <FaCheckCircle />;
      alertClass = 'alert-success';
      break;
    case 'error':
      icon = <FaTimesCircle />;
      alertClass = 'alert-error';
      break;
    case 'info':
      icon = <FaInfoCircle />;
      alertClass = 'alert-info';
      break;
    case 'warning':
      icon = <FaExclamationCircle />;
      alertClass = 'alert-warning';
      break;
    default:
      icon = <FaInfoCircle />;
      alertClass = 'alert-info';
      break;
  }

  return (
    <div className={`alert ${alertClass}`}>
      <div className="alert-icon-container">
        <div className="alert-icon">
          {icon}
        </div>
      </div>
      <div className="alert-content">
        <span className="alert-title">{title}</span>
        <p className="alert-message">{message}</p>
      </div>
      <button className="dismiss" type="button" onClick={onClose}>×</button>
    </div>
  );
};

export default Alert;
