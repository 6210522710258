import React from 'react';
import '../StyleSheets/Card.css';

const Card = ({ title, description, actionText, onAction, icon }) => {
  return (
    <div className="card">
      <div className="icon-container">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
      <button onClick={onAction}>{actionText}</button>
    </div>
  );
};

export default Card;
