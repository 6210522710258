import React from 'react';
import '../stylesheets/Services.css';
import Service from './microcomponents/Service';

import { FaBullhorn } from 'react-icons/fa';
import { FaHiking } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaRuler } from 'react-icons/fa';

function Services() {
  const servicePublicity = {
    title: 'Publicity',
    description: "Creation of audiovisual content for the promotion of companies focused on the tourism sector through the taking and editing of videos.",
    icon: <FaBullhorn className="icon" size={50} />,
    link: '/adpackages'
  };
  const serviceHikings = {
    title: 'Hiking',
    description: "Exotic tourist destinations focused on art and the beauty of nature, making known every wonderful corner of this beautiful country that is Costa Rica. Unique and incredible experiences behind each destination",
    icon: <FaHiking className="icon" size={50} />,
    link: '/hiking'
  };
  const serviceRealStateSales = {
    title: 'Real State Sales',
    description: "Shooting CR excels in showcasing properties through tailored and creative methods, utilizing 3D videos, photography, and precise measurements. Our approach highlights each property's distinct attributes to attract buyers and drive successful transactions.",
    icon: <FaHandshake className="icon" size={50} />,
    link: '/realstatesales'
  };
  const ServicePhotogrammetry = {
    title: 'Photogrammetry',
    description: "Presentation of terrains or plans in a three-dimensional way through multiple frames, making known the properties of a specific shape and with visualization of any desired angle, in the same way making known their cubic and square measurements.",
    icon: <FaRuler className="icon" size={50} />,
    link: 'photogrammetrypackages'
  };

  return (
    <section className="services-container" id="services">
      <div className="services-desc-container">
        <h1 className="secondary-heading">Services</h1>
        <h2 className="secondary-subheading">Our services</h2>
        <p className="secondary-text">We are dedicated to providing high quality services</p>
      </div>
      <div className="services-items-container">
        <Service props={servicePublicity} />
        <Service props={serviceHikings} />
        <Service props={serviceRealStateSales} />
        <Service props={ServicePhotogrammetry} />
      </div>
    </section>
  );
}

export default Services;
