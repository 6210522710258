import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Loader from '../../../Loader';
import { useAlert } from '../../../AlertContainer';
import '../../StyleSheets/AdminPanel.css';

const RealStateSalesList = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { addAlert } = useAlert();

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      setLoading(true); 
      const response = await fetch('https://shootingcr.somee.com/api/RealEstateSale');
      const data = await response.json();
      setSales(data);
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleCreate = () => {
    navigate('/admin/realstatesales/create');
  };

  const handleEdit = (id) => {
    navigate(`/admin/realstatesales/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true); 
      const response = await fetch(`https://shootingcr.somee.com/api/RealEstateSale/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        setSales(sales.filter(sale => sale.id !== id));
        addAlert('success', 'Success', 'Sale deleted successfully!');
      } else {
        console.error('Error deleting sale:', await response.text());
        addAlert('error', 'Error', 'Error deleting sale.');
      }
    } catch (error) {
      console.error('Error deleting sale:', error);
      addAlert('error', 'Error', 'Error deleting sale.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-panel">
      <Sidebar />
      <div className="main-content">
        <header className="header">
          <Navbar />
        </header>
        <div className="real-state-sales">
          {loading && <Loader />} 
          <h1>Real Estate Sales</h1>
          <button className="btn create-btn" onClick={handleCreate}>Create New Sale</button>
          <table className="list-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Property Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sales.map(sale => (
                <tr key={sale.id}>
                  <td>{sale.id}</td>
                  <td>{sale.propertyName}</td>
                  <td>{sale.description}</td>
                  <td>{sale.price}</td>
                  <td>
                    <button className="btn edit-btn" onClick={() => handleEdit(sale.id)}>Edit</button>
                    <button className="btn delete-btn" onClick={() => handleDelete(sale.id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RealStateSalesList;
